import Chart from 'components/Chart'
import PieChartView from 'components/PieChart';
import LearningPacks from './LearningPacks'
import SectionLabel from 'components/Section'
import { useDispatch, useSelector } from "react-redux";
import {
  REACT_APP_METABASE_USER_OUTCOME_BREAKDOWN,
  REACT_APP_METABASE_FEEDBACK_ON_TF_PROFESSIONAL,
  REACT_APP_METABASE_MOOD_OBSERVATION_GROUPED_BY_MOOD,
  REACT_APP_METABASE_MOOD_HEATMAP,
  REACT_APP_METABASE_REASON_MOOD,
  REACT_APP_METABASE_MOOD_BREAKDOWN,
  REACT_APP_METABASE_FREQUENCY_POSITIVE_MOOD_REASONS,
  REACT_APP_METABASE_FREQUENCY_NEGATIVE_MOOD_REASONS,
} from 'constants/megabase'
import { selectAuth } from 'containers/SingIn/authSlice';
import { refreshProgramAction } from 'containers/SingIn/authAPI';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { add_params_and_push } from 'utils';
const AppUsage = () => {
  const {
    app,
  } = useSelector(selectAuth);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    dispatch(refreshProgramAction(location.search));
    add_params_and_push({typeFilterDatetime: 0}, location, queryString, history)

  }, []);
  return (
    <main>
      <div className="py-6 bg-grey">
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-bold text-blue mb-4">App Usage</h1>
        </div>
        <div className="py-4">
          <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
            <SectionLabel title="Engagement" />
            <div className="flex flex-wrap justify-between space-x-8  ">
              <PieChartView className={'bg-white shadow sm:rounded-lg flex-1'} title={'In-App Sections'} />
            </div>
          </div>
          <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
            <SectionLabel title="Learning Packs" />
            <div className="flex flex-wrap justify-between space-x-8  ">
              <LearningPacks rating={app?.app_rating?.toFixed(1)} className={'bg-white shadow sm:rounded-lg flex-1'} title={'Top Learning Packs'} />
            </div>
          </div>
          <div className="mx-auto px-4  sm:px-6 md:px-8 mb-10">
            <SectionLabel title="Mood Summary" />

            <div className="flex flex-wrap justify-between space-x-8  mb-10">
            <Chart 
                  height={"600px"} 
                  className={'bg-white shadow sm:rounded-lg flex-1 mt-6'} 
                  question={REACT_APP_METABASE_MOOD_BREAKDOWN} />
            </div>

            <div className="flex flex-wrap justify-between space-x-8  mb-10">
            <Chart 
                  height={"600px"} 
                  className={'bg-white shadow sm:rounded-lg flex-1 mt-6'} 
                  question={REACT_APP_METABASE_FREQUENCY_POSITIVE_MOOD_REASONS} />
            </div>

            <div className="flex flex-wrap justify-between space-x-8  mb-10">
            <Chart 
                  height={"600px"} 
                  className={'bg-white shadow sm:rounded-lg flex-1 mt-6'} 
                  question={REACT_APP_METABASE_FREQUENCY_NEGATIVE_MOOD_REASONS} />
            </div>

            <div className="flex flex-wrap justify-between space-x-8  ">
              <Chart
                description="Mood observation grouped by mood"
                height={"600px"}
                className={'bg-white shadow sm:rounded-lg flex-1'}
                question={REACT_APP_METABASE_MOOD_OBSERVATION_GROUPED_BY_MOOD} />
            </div>
          </div>




          <div className="mx-auto px-4 pt-10 sm:px-6 md:px-8">
            <div className="flex flex-wrap justify-between space-x-8  ">
              <Chart
                description="Frequency of mood based on days"
                height={"780px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_MOOD_HEATMAP} />
            </div>
          </div>
          <div className="mx-auto px-4 pt-10 sm:px-6 md:px-8 mb-10">
            <div className="flex flex-wrap justify-between space-x-8  ">
              <Chart
                description="Frequency of reasons based on moods"
                height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_REASON_MOOD} />
            </div>
          </div>
          <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
            <SectionLabel title="User Feedback" />
            <div className="">

              <Chart
                description="User outcome breakdown"
                height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1 h-150 overflow-hidden '} question={REACT_APP_METABASE_USER_OUTCOME_BREAKDOWN} />
              {/* <RatingBar rating={app?.app_rating?.toFixed(1)} className={'bg-white shadow sm:rounded-lg flex-1 mt-12'} title={'App Rating'} /> */}
            </div>
          </div>
          <div className="mx-auto px-4 sm:px-6 md:px-8">
            <div className="flex flex-wrap justify-center">
              <Chart
                description="Feedback on TF Professional"
                height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_FEEDBACK_ON_TF_PROFESSIONAL} />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default AppUsage