import { useSelector } from 'react-redux';
import { selectAuth } from "containers/SingIn/authSlice";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { REACT_APP_METABASE_AIA_PRODUCT_AND_SERVICE_ENGAGEMENT } from 'constants/megabase';
import SectionLabel from 'components/Section';
import images from 'assets/images';
import ChartPdf from 'containers/ExportPdf/ChartPdf';

const AiaOverviewPdf = (props: any) => {
    const location = useLocation();
    const { year } = useSelector(selectAuth);
    const _year = new URLSearchParams(location.search).get('year') || year;
    const month_quarter = new URLSearchParams(location.search).get('month_quarter') || "Entire year"

    const overallUserRating = new URLSearchParams(location.search).get('overallUserRating') || 0;
    const totalRegisterUsers = new URLSearchParams(location.search).get('totalRegisterUsers') || 0;
   
    const formatNumber = (num: any) => {
        // Check if the number has any decimal places
        if (num && num % 1 !== 0) {
          // If it does, format it to 1 decimal places
          return (parseFloat(num).toFixed(1));
        }
        // If it's an integer, return it as is
        return num;
      }
   
    return (
        // myiFrame id is needed to be able to print to the file (can refer to the lambda function)
        <main id="myiFrame" className="px-6 pt-6">
          <div className=" mx-auto px-1 sm:px-6 md:px-8 flex justify-between">
                        <div>
                        <h1 className="text-2xl font-semibold text-blue">{`THOUGHTFULL X AIA CS - ${_year} ${month_quarter} REVIEW`}</h1>

                        </div>
                        <div className="flex">
                            <div>
                                <img
                                    className="h-24 w-auto"
                                    style={{ width: '175px', height: '46px' }}
                                    src={images.tf_aia_logo}
                                    alt="Workflow"
                                />
                            </div>
                            <div>
                                <img
                                    className="h-24 w-auto"
                                    style={{ width: '126px', height: '46px' }}
                                    src={images.aia_logo}
                                    alt="Workflow"
                                />
                            </div>

                        </div>
                    </div>
                    <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-6">

            <SectionLabel key={'SectionLabel-3'} title="Overview" />
            <div className="text-center" style={{ color: "#004876" }}> <span className="text-xl mr-3">{_year} Total Registered Users: {totalRegisterUsers?.toLocaleString('en-US')}</span>
                        <span className="text-xl ml-3">Overall User Rating: {formatNumber(overallUserRating)}</span>
                    </div>
            <div className="flex flex-wrap justify-between space-x-8  mb-10">
                <ChartPdf
                    height={"75vh"}
                    className={'bg-white shadow sm:rounded-lg flex-1 mt-6'}
                    question={REACT_APP_METABASE_AIA_PRODUCT_AND_SERVICE_ENGAGEMENT}
                />
            </div>
        </div>
        </main>
        
    )
}

export default AiaOverviewPdf;
