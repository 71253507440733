import SupersetView from "../../components/Superset";
import {OVERVIEW_DASHBOARD_ID} from "../../constants/superset";

const Overview = () => {
      return (
          <div className="bg-grey min-h-screen">
              <SupersetView dashboardId={OVERVIEW_DASHBOARD_ID} />
          </div>
      );
}

export default Overview