import {useSelector} from "react-redux";
import {selectAuth} from "../SingIn/authSlice";

const ExportPdf = () => {
    const {org} = useSelector(selectAuth);
    return (
        <main>
            <div className="bg-grey min-h-screen">
            </div>
        </main>
    );
}

export default ExportPdf