import { useSelector } from 'react-redux';
import { selectAuth } from "containers/SingIn/authSlice";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import SectionLabel from 'components/Section';
import DbCircleChart from 'components/DbCircleChart';

const BehaviouralInsightsPdf = (props: any) => {
    const location = useLocation();
    const { year, month } = useSelector(selectAuth);
    const decode_and_parse: any = (key: string, defaultValue: string) => {
        const value = new URLSearchParams(location.search).get(key) || defaultValue;
        return JSON.parse(decodeURIComponent(value))
    }
    const _year = new URLSearchParams(location.search).get('year') || year;
    const month_quarter = new URLSearchParams(location.search).get('month_quarter') || "Entire year"
    const webinars = decode_and_parse("webinars", "[]");
    const top3Topics = decode_and_parse("top3Topics", "[]");

    return (
        // myiFrame id is needed to be able to print to the file (can refer to the lambda function)
        <main id="myiFrame" className="px-6">
            <SectionLabel key={'SectionLabel-3'} title="Behavioural Insights" />

            <div className="bg-white p-6">
                <div className="mb-6">
                    <h3 style={{ color: "#004876" }} className="text-xl font-bold">Activation Programme: Webinars</h3>
                    <div className="flex space-x-4 mt-2">
                        <div className="flex items-center justify-center">
                            <div style={{ backgroundColor: "#009383" }} className="rounded-full h-5 w-5">

                            </div>
                            <div className="ml-2">
                                {month_quarter} {_year}
                            </div>

                        </div>
                        <div className="flex items-center justify-center">
                            <div style={{ backgroundColor: "#004876" }} className="rounded-full h-5 w-5 ml-4">

                            </div>
                            <div className="ml-2">
                                FY{_year}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-6">
                    {
                        [0, 1, 3].map(index => (
                            <div className="flex flex-col items-center ">
                                <DbCircleChart key={index} month={webinars[index] && webinars[index][1]} year={webinars[index] && webinars[index][2]} />
                                <span style={{ color: "#4B5563" }} className="text-sm text-center text-blue-800 mt-1">{webinars[index] && webinars[index][0]}</span>
                            </div>
                        ))
                    }
 <div className="">
            <div style={{color: "#004876"}} className="text-lg">Top 3 Selected Topics</div>
            <div className="mt-4">
                 { [0,1,2].map(index =>   
                        <div key={index} className="bg-green-50 rounded-lg flex mt-2">
                    <div style={{color: "#009383"}} className="text-2xl font-bold">{index + 1}.</div>
                    <div className='ml-4' >{(top3Topics[index] &&top3Topics[index][0]) || "-" }</div>
                </div>
                    )}
            </div>
          
        </div>
                </div>
                {/* <div className="mt-6">
                    <div style={{ color: "#004876" }} className="text-lg">Top 3 Selected Topics</div>
                    <div className="">
                        {[0, 1, 2].map(index =>
                            <div key={index} className="bg-green-50 p-4 rounded-lg">
                                <span style={{ color: "#009383" }} className="text-2xl font-bold">{index + 1}.</span>
                                <span className='ml-4' >{(top3Topics[index] && top3Topics[index][0]) || "-"}</span>
                            </div>
                        )}
                    </div>


                </div> */}
            </div>
        </main>

    )
}

export default BehaviouralInsightsPdf;
