import {useSelector} from "react-redux";
import {selectAuth} from "../../containers/SingIn/authSlice";
import {useEffect} from "react";
import {embedDashboard} from "@superset-ui/embedded-sdk";
import * as process from "process";

interface SupersetViewProps {
    dashboardId: string;
}

const SupersetView = (props: SupersetViewProps) => {
    const {org} = useSelector(selectAuth);
    const screenHeight = window.innerHeight;
    const supersetDomain = process.env.REACT_APP_SUPERSET_DOMAIN;

    useEffect(() => {
        if(org && supersetDomain){
            initSuperSetDashboard();
        }
    }, [org, supersetDomain]);

    const initSuperSetDashboard = async () => {
        const token = await fetchGuestToken();
        await embedDashboard({
            id: props.dashboardId,
            supersetDomain: supersetDomain!,
            mountPoint: document.getElementById("dashboard")!,
            fetchGuestToken: () => token,
            dashboardUiConfig: {
                hideTitle: true,
                hideTab:true,
                filters:{
                    expanded:true
                },
                urlParams:{
                    standalone: 3
                }
            }
        })

        const iframe = document.querySelector("iframe");
        if (iframe) {
            iframe.style.width = '100%';
            iframe.style.minHeight = `${screenHeight}px`;
        }
    }

    const fetchAccessToken = async () => {
        try {
            const body = {
                username: process.env.REACT_APP_SUPERSET_ADMIN_USERNAME,
                password: process.env.REACT_APP_SUPERSET_ADMIN_PASSWORD,
                provider: process.env.REACT_APP_SUPERSET_PROVIDER,
                refresh: true,
            };

            const response = await fetch(
                `${supersetDomain}/api/v1/security/login`,
                {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const jsonResponse = await response.json();
            return jsonResponse?.access_token;
        } catch (e) {
            console.error(e);
        }
    }

    const fetchGuestToken = async () => {
        const accessToken = await fetchAccessToken();
        try {
            const body = {
                resources: [
                    {
                        type: "dashboard",
                        id: props.dashboardId,
                    },
                ],
                rls: [
                    {
                        "clause": `organization_id=${org!.id}`
                    }
                ],
                user: {
                    username: "",
                    first_name: "",
                    last_name: "",
                },
            };
            const response = await fetch(
                `${supersetDomain}/api/v1/security/guest_token/`,
                {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    redirect: "follow",
                }
            );
            const jsonResponse = await response.json();
            return jsonResponse?.token;
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="flex-1 w-full h-full min-h-screen">
            <div id="dashboard" className="min-h-screen"></div>
        </div>
    );
}

export default SupersetView;