import SupersetView from "../../components/Superset";
import {CLINICAL_INSIGHTS} from "../../constants/superset";

const ClinicalInsights = () => {
    return (
        <div className="bg-grey min-h-screen">
            <SupersetView dashboardId={CLINICAL_INSIGHTS}/>
        </div>
    );
}

export default ClinicalInsights
