import SupersetView from "../../components/Superset";
import {UTILIZATION_INSIGHTS} from "../../constants/superset";

const UserDemographics = () => {
    return (
        <div className="bg-grey min-h-screen">
            <SupersetView dashboardId={UTILIZATION_INSIGHTS} />
        </div>
    );
}

export default UserDemographics